import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"

const RelatedPosts = ({ currentPost }) => {
  const tags = currentPost.frontmatter.tags
  return (
    <StaticQuery
      query={graphql`
        query RelatedPosts {
          allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: 1000
            filter: {fileAbsolutePath: {regex: "\\/blog/"}}
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                  tags
                }
              }
            }
          }
        }
      `}
      render={data => {
        const posts = data.allMarkdownRemark.edges

        let related = posts.filter(post => {
          return post.node.frontmatter.tags.some(t => tags.includes(t))
        }).filter(post => {
          return post.node.frontmatter.title !== currentPost.frontmatter.title
        }).sort(() => {
          return 0.5 - Math.random()
        }).slice(0, 3).sort((a, b) => {
          return a.node.frontmatter.title > b.node.frontmatter.title
        })

        if (related.length === 0) {
          related = posts.sort(() => {
            return 0.5 - Math.random()
          }).slice(0, 3).sort((a, b) => {
            return a.node.frontmatter.title > b.node.frontmatter.title
          })
        }

        return (
          <div>
            <h3 style={{ marginTop: 0}}>You might enjoy...</h3>
            <ul>
              {related.sort().map(( post, index ) => {
                return (
                  <li key={index}>
                    <Link to={`/posts${post.node.fields.slug}`}>
                      {post.node.frontmatter.title}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        )
      }}
    />
  )
}

export default RelatedPosts
