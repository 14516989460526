import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  TwitterShareButton,
  LinkedinShareButton,
  RedditShareButton,
  FacebookShareButton
} from 'react-share';
import {  
  faTwitter,
  faLinkedin,
  faRedditAlien,
  faFacebook
} from '@fortawesome/free-brands-svg-icons'
import { rhythm } from '../utils/typography';

const Share = ({ siteMetadata, slug }) => {
  const siteUrl = siteMetadata.siteUrl
  const siteTitle = siteMetadata.title
  const twitterHandle = siteMetadata.social.twitter
  return (
    <div
      className="post-social"
      style={{
        marginTop: rhythm(0.5)
      }}
    >
      <span style={{paddingRight: `10px`}}>Share: </span>
      <ul className="list-inline">
        <li>
          <FacebookShareButton 
            url={`${siteUrl}/posts${slug}`}
            className="button"
          >
            <span className="icon" style={{paddingRight: rhythm(0.25)}}>
              <FontAwesomeIcon icon={faFacebook} />
            </span>
            <span className="text">Facebook</span>
          </FacebookShareButton>
        </li>
        <li>
          <LinkedinShareButton 
            url={`${siteUrl}/posts${slug}`}
            title={siteTitle}
            className="button"
          >
            <span className="icon" style={{paddingRight: rhythm(0.25)}}>
              <FontAwesomeIcon icon={faLinkedin} />
            </span>
            <span className="text">LinkedIn</span>
          </LinkedinShareButton>
        </li>
        <li>
          <RedditShareButton 
            url={`${siteUrl}/posts${slug}`}
            title={siteTitle}
            className="button"
          >
            <span className="icon" style={{paddingRight: rhythm(0.25)}}>
              <FontAwesomeIcon icon={faRedditAlien} />
            </span>
            <span className="text">Reddit</span>
          </RedditShareButton>
        </li>
        <li>
          <TwitterShareButton 
            url={`${siteUrl}/posts${slug}`}
            via={twitterHandle.split('@').join('')}
            className="button"
          >
            <span className="icon" style={{paddingRight: rhythm(0.25)}}>
              <FontAwesomeIcon icon={faTwitter} />
            </span>
            <span className="text">Twitter</span>
          </TwitterShareButton>
        </li>
      </ul>
    </div>
  );
};

export default Share;
