import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import TagList from "../components/tagList"
import Share from "../components/share"
import RelatedPosts from "../components/relatedPosts"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  const image = data.file 
    ? data.file.childImageSharp.resize
    : null

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={image}
      />
      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1.5),
              marginBottom: 0,
              paddingBottom: rhythm(0.25)
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.date}
          </p>
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <footer>
          <div style={{ paddingBottom: rhythm(1) }}>
            <span style={{paddingRight: `5px`}}>Tags: </span>
            {post.frontmatter.tags !== undefined && 
              post.frontmatter.tags.length > 0 ?
              <TagList tags={post.frontmatter.tags} /> :
              "none"}
            <Share 
              siteMetadata={data.site.siteMetadata}
              slug={data.markdownRemark.fields.slug}
            />
          </div>
        </footer>
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
      </article>
      <RelatedPosts currentPost={post} />
      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />
      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
            marginLeft: 0,
            marginBottom: rhythm(0.5)
          }}
        >
          <li>
            {previous && (
              <Link to={`/posts${previous.fields.slug}`} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`/posts${next.fields.slug}`} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        social {
          twitter
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
    file(relativePath: {eq: "images/social_card.png"}) {
      childImageSharp {
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
  }
`
